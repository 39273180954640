<template>
  <div class="service-form-part">
    <MarketSegmentRadioFormPart :choices="marketSegments" v-on="$listeners" />

    <h2 class="form-modal__part-title">
      {{ $t('steps.typeOfAssignment') }}
    </h2>

    <ServiceRadioField :choices="serviceSelections" v-on="$listeners" />
  </div>
</template>

<script>
import {
  solarPanelsPurchaseNL,
  solarPanelsPurchaseSolarCollectorsNL,
  solarPanelsRentNL,
} from 'chimera/solarPanels/service'
import { Selectable } from 'chimera/all/components/models/Selectable'
import MarketSegmentRadioFormPart from 'chimera/all/components/form/parts/marketSegment/MarketSegmentRadioFormPart.vue'
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField.vue'

export default {
  name: 'SortedServiceRadioFormPart',

  components: {
    ServiceRadioField,
    MarketSegmentRadioFormPart,
  },

  props: {
    /**
     * @type {string}
     */
    serviceSort: {
      type: Function,
      required: true,
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      marketSegments: [
        new Selectable(
          'Woonhuis',
          'Woonhuis',
          this.$t('field.marketSegment.consumer'),
        ),
        new Selectable(
          'Bedrijfspand',
          'Bedrijfspand',
          this.$t('field.marketSegment.corporate'),
        ),
        new Selectable(
          'Agrarische onderneming',
          'Agrarische onderneming',
          this.$t('field.marketSegment.corporate'),
        ),
        new Selectable(
          'Groot zakelijke projecten',
          'Groot zakelijke projecten',
          this.$t('field.marketSegment.corporate'),
        ),
      ],

      serviceSelections: [
        new Selectable(
          'Zonnepanelen koop',
          'Zonnepanelen koop',
          solarPanelsPurchaseNL.id,
        ),
        new Selectable(
          'Zonnepanelen huur',
          'Zonnepanelen huur',
          solarPanelsRentNL.id,
        ),
        new Selectable(
          'Zonneboiler / Zonnecollectoren',
          'Zonneboiler / Zonnecollectoren',
          solarPanelsPurchaseSolarCollectorsNL.id,
        ),
      ],
    }
  },

  /**
   * Let's order the services using the external function.
   */
  mounted() {
    this.serviceSort(this.serviceSelections)
  },
}
</script>
