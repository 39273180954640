<template>
  <FormModal title="Soort dak" show-back-btn>
    <template #body>
      <RoofTypeElectricityEstimationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import RoofTypeElectricityEstimationFormStep from '~/components/form/steps/roofTypeElectricityEstimation/RoofTypeElectricityEstimationFormStep'

export default {
  components: {
    RoofTypeElectricityEstimationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Soort dak en schatting jaarlijks elektriciteitsverbruik',
      headDescription:
        'Geef aan om welk soort dak het gaat en wat je schatting van het jaarlijks elektriciteitsverbruik is.',
      path: '/offertes-aanvragen/soort-dak-schatting-jaarlijks-electriciteitsverbruik',
      progressValue: 50,
      checkoutStep: 2,
    }
  },
}
</script>
