<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <SortedServiceRadioFormPart
        :service-sort="orderServices"
        @change="onChange"
        @result="onResult"
      />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import SortedServiceRadioFormPart from 'chimera/solarPanels/components/form/parts/service/SortedServiceRadioFormPart.nl'
import {
  solarPanelsPurchaseNL,
  solarPanelsRentNL,
} from 'chimera/solarPanels/service'
import NextStep from '~/pages/offertes-aanvragen/soort-dak-schatting-jaarlijks-electriciteitsverbruik'

export default {
  name: 'ServiceTypeFormStep',

  components: {
    SortedServiceRadioFormPart,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },

    /**
     * Depending on the landing page the services should be ordered differently.
     *
     * @param services
     * @returns {*}
     */
    orderServices(services) {
      const landingPageIdentifier =
        this.$store.getters['configuration/getLandingPage']('identifier')
      const serviceIdToOrderOn =
        this.getLandingPageMapping()[landingPageIdentifier]

      // When not coming from a relevant landing page we keep ordering intact.
      if (!serviceIdToOrderOn) {
        return services
      }

      // When coming from the rent landing page the rent service should be ordered on top.
      return services.sort((service) => {
        if (service.value === serviceIdToOrderOn) {
          return -1
        } else {
          return 1
        }
      })
    },
    /**
     * @returns {{}}
     */
    getLandingPageMapping() {
      const mapping = {}
      mapping['landing-page-zonnepanelenvergelijker-nl-huren'] =
        solarPanelsRentNL.id
      mapping['landing-page-zonnepanelenvergelijker-nl-kopen'] =
        solarPanelsPurchaseNL.id
      return mapping
    },
  },
}
</script>
