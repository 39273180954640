<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <RoofTypeFormPart @result="onResult" @change="onChange" />

      <h2 class="form-modal__part-title">
        Schatting jaarlijks elektriciteitsverbruik
      </h2>

      <EstimatedAnnualEnergyConsumptionFormPart
        @result="onResult"
        @change="onChange"
      />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import RoofTypeFormPart from 'chimera/solarPanels/components/form/parts/roofType/RoofTypeFormPart'
import EstimatedAnnualEnergyConsumptionFormPart from 'chimera/solarPanels/components/form/parts/estimatedAnnualEnergyConsumption/EstimatedAnnualEnergyConsumptionFormPart'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import Step5 from '~/pages/offertes-aanvragen/je-gegevens'

export default {
  name: 'RoofTypeElectricityEstimationFormStep',

  components: {
    RoofTypeFormPart,
    EstimatedAnnualEnergyConsumptionFormPart,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  data: () => ({
    willTransitionOnValid: true,
  }),

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(Step5)
    },
  },
}
</script>
